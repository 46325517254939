<template>
  <div>
    <div class="header--background" :class="headerClass(customizationHeader.headerAlignment)" :style="{background: customizationHeader.headerBackgroundColor}">
    <b-navbar-nav toggleable="xl" type="dark" variant="primary">
      <b-navbar-brand class="m-0"><img class="header--logo" :src="customizationHeader.headerLogo"></b-navbar-brand>
    </b-navbar-nav>
    </div>
  </div>
</template>
<script>
import {
  BNavbarNav,
  BNavbarBrand
} from 'bootstrap-vue'
export default {
  props: [
    'customizationHeader'
  ],
  components: {
    BNavbarNav,
    BNavbarBrand
  },
  methods: {
    headerClass (align) {
      let response = ''
      switch (align) {
      case 'center':
        response = 'justify-content-center'
        break
      case 'right':
        response = 'justify-content-end'
        break
      default:
        break
      }
      return response
    }
  }
}
</script>
<style lang="scss" scoped>
  .header--background {
    background-color: #FFFFFF;
    height: 117px;
    align-items: center;
    display: flex;
  }
  .header--logo {
    height: 64px;
  }
</style>