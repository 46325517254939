<template>
  <div class="report--component" >
    <Header :customizationHeader="customizationHeader" ></Header>
    <div v-if="statusView==='validForm'" >
      <Body :customizationBody="customizationBody" :token="auth" @sendForm="setStatusViewConfirmData" :formSelect="formSelect" :relatedData="relatedData"></Body>
    </div>
    <div v-else>
      <!-- <end-messages :customizationBody="customizationBody" :paramsMessage="paramsMessage" ></end-messages> -->
      <end-messages-horizontal :customizationBody="customizationBody" :paramsMessage="paramsMessage" :relatedData="relatedData"></end-messages-horizontal>
    </div>
  </div>
</template>
<script>
import Header from './component/HeaderReport.vue'
import Body from './component/BodyReport.vue'
import BaseServices from '@/store/services/index'
// import EndMessages from './ErrorMessages/EndMessages.vue'
import EndMessagesHorizontal from './ErrorMessages/EndMessagesHorizontal.vue'

export default {
  components: { Header, Body, EndMessagesHorizontal },
  data() {
    return {
      auth: '',
      statusView: 'firstView',
      customizationHeader: {
        headerLogo: '',
        headerBackgroundColor: ''
      },
      customizationBody: {},
      paramsMessage: {},
      baseService: new BaseServices(this),
      formSelect: '',
      relatedData: {},
      placesOptions: []
    }
  },
  computed: {
    errorDefault() {
      return {
        imgUrl: 'https://storage.googleapis.com/tickets-misc/external_form_assets/%C3%8Dcono.Circle.Error.svg',
        message1: '¡Ocurrió un problema al procesar los datos del formulario!',
        message2: 'Por favor, inténtelo de nuevo más tarde.',
        ot: ''
      }
    }
  },
  mounted() {
    this.auth = this.$route.query.auth
    this.getCustomization(this.auth)
  },
  methods: {
    validateExp (exp) {
      const todayUnixDate =  Math.floor(new Date() / 1000)
      return (exp - todayUnixDate) / (60 * 60 * 24) >= 0
    },
    getCustomization (token) {
      this.baseService.callService('getCustomization', {token})
        .then(response => {
          this.formSelect = response.type ?? ''
          if (response.related_data) this.relatedData = {...response.related_data}
          this.customizationHeader = {
            ...this.customizationHeader, 
            headerLogo: response.customizations.image_header_url,
            headerBackgroundColor: response.customizations.header_background_color,
            headerAlignment: response.customizations.header_align
          }
          this.customizationBody = {
            ...this.customizationBody,
            textColorBodyWeb: response.customizations.text_color_body_web,
            bodyBackgroundColor: response.customizations.background_color_body_web,
            buttonColor: response.customizations.buttons_color,
            buttonTextColor: response.customizations.buttons_text_color,
            nameCompany: response.customizations.name_company ?? 'La empresa'
          }
          if (!!response.confirm_data) {
            this.setStatusViewConfirmData(response.confirm_data)
          } else {
            this.setStatusView('validForm')
          }
        })
        .catch(err => {
          this.setStatusViewConfirmData()
        })
    },
    setStatusViewConfirmData(data = null) {
      this.paramsMessage = !!data ? {
        imgUrl: data.result_image_url,
        message1: data.tittle_1,
        message2: data.tittle_2,
        ot: data.result_image_text
      } : this.errorDefault
      this.setStatusView('showMessage')
    },
    setStatusView (statusView) {
      this.statusView = statusView
    }
    
  }
}
</script>
<style lang="scss" scoped>
  .report--component {
    font-size: 19px;
    // font-family: 'HK Grotesk';
  }
</style>