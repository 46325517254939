<template>
  <b-card body-class="end--message--container" :style="{background: customizationBody.bodyBackgroundColor}">
    <information-card :paramsMessage="paramsMessage" :relatedData="relatedData"></information-card>
    <message-card :paramsMessage="paramsMessage"></message-card>
  </b-card>
</template>
<script>
import informationCard from './InformationCard.vue'
import messageCard from './MessageCard.vue'
export default {
  props: [
    'customizationBody', 'paramsMessage', 'relatedData'
  ],
  components: {informationCard, messageCard}
}
</script>
<style lang="scss">
  .end--message--container {
    display: grid;
    height: calc(100vh - 117px);
    width: 100vw;
    justify-items: center;
    align-items: center;
    grid-template-columns: 30% 1fr;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    max-height: 2000px;
  }
</style>