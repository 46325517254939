<template>
  <div class="message--card--container w-100">
    <p class="message--one">{{paramsMessage.message1}}</p>
    <p class="message--two">{{paramsMessage.message2}}</p>
  </div>
</template>
<script>
export default {
  props: ['paramsMessage']
}
</script>
<style lang="scss">
  .message--card--container {
    padding: 1rem;

    .message--one {
      font-family: Montserrat;
      font-size: 30px;
      font-weight: 500;
      line-height: 43px;
      letter-spacing: 0em;
      text-align: center;
    }
    .message--two {
      font-family: Montserrat;
      font-size: 25px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
</style>