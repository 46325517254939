<template>
    <div class="body--report"  :style="{background: customizationBody.bodyBackgroundColor}" >
      <h1 class="body--title" :style="{color: customizationBody.textColorBodyWeb}" >{{ textDataHeader('title') }}</h1>
      <p :style="{color: customizationBody.textColorBodyWeb}">{{ textDataHeader('text') }}</p>
      <div class="body--form" v-if="formSelect === 'report-problem'">
        <FormReport :customizationForm="customizationBody" :token="token" @sendForm="sendForm"></FormReport>
      </div>
      <div class="body--form" v-if="formSelect === 'change-address'">
        <FormChangeDirection :customizationForm="customizationBody" :token="token" @sendForm="sendForm" :relatedData="relatedData"></FormChangeDirection>
      </div>
    </div>
</template>
<script>
import FormReport from './FormReport.vue'
import FormChangeDirection from './FormChangeDirection.vue'
export default {
  props: [
    'customizationBody',
    'token',
    'formSelect',
    'relatedData'
  ],
  components: {
    FormReport,
    FormChangeDirection
  },
  data() {
    return {
    }
  },
  computed: {
    dataHeaders() {
      return [
        {
          id: 'report-problem',
          title: 'Reportar problema',
          text: 'Reporta inconvenientes, daños y faltantes del paquete.'
        },
        {
          id: 'change-address',
          title: this.relatedData?.delivery?.tracking_number ? `Pedido ${this.relatedData?.delivery?.tracking_number}` : 'Verificar dirección',
          text: 'Si la dirección es precisa, haz clic en "Verificar Dirección". Si no es correcta, ajusta la ubicación arrastrando el pin en el mapa o intenta corregirla manualmente. Después de hacer la corrección, haz clic en el botón correspondiente.'
        }
      ]
    }
  },
  methods: {
    sendForm (data) {
      this.$emit('sendForm', data)
    },
    textDataHeader(source) {
      return this.dataHeaders.find(el => el.id === this.formSelect)[source]
    }
  }
}
</script>
<style lang="scss" scoped>
  .body--report {
    background-color: #39549F;
    height: calc(100vh - 117px);
    color: white;
    padding: 19px 48px;
  }
  .body--title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #FFFFFF;
    margin-bottom: 3rem;
  }
  .body--form {
    display: grid;
    justify-items: center;
  }
</style>