<template>
  <div class="information--card--line w-100">
    <div class="information--card--container m-0 w-100">
      <img class="img--message" 
        :src="paramsMessage.imgUrl" 
        alt="" srcset="">
      <p class="text">
        <span class="text--span">Pedido</span> {{textCard}}
      </p> 
    </div>
  </div>
</template>
<script>
export default {
  props: ['paramsMessage', 'relatedData'],
  computed: {
    textCard() {
      return this.paramsMessage?.ot ? this.paramsMessage?.ot : this.relatedData?.delivery?.tracking_number ? this.relatedData?.delivery?.tracking_number : ''
    }
  }
}
</script>
<style lang="scss">
  .information--card--container {
    display: grid;
    justify-items: center;
    gap: 50px;
    .img--message {
      width: 176px;
    }
    .text {
      font-family: Montserrat;
      font-size: 25px;
      font-weight: 500;
      line-height: 37px;
      letter-spacing: 0em;
      text-align: center;
    }
    .text--span {
      font-weight: 700;
    }
  }
  .information--card--line {
    height: calc(100% - 100px);
    display: grid;
    justify-content: center;
    align-content: center;
    border-right: .5px solid #6E6B7B;
  }
</style>